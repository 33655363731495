.main-forgotten {
  position: relative;
}

.txtflds {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 20px;
  margin-left: 25%;
  margin-bottom: 0px;
}

.belowInfo {
  font-family: Inter;
  font-size: 13px;
  color: #444444;
  text-align: center;
}

.butts {
  box-sizing: border-box;
  white-space: nowrap;
  background-color: #b33951;
  border: none;
  color: white;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: Poppins;
  cursor: pointer;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  width: 40%;
  margin-top: 40px;
  margin-bottom: 15px;
  margin-left: 30%;
  border-radius: 5px;
  font-weight: 600;
  z-index: 0;
}

.butts:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
