.mainRefresh {
    width: 150px;
    z-index: 1;
    position: absolute;
    top: 30px;
    right: 40px;
    border: 2px solid #B33951 ;
    border-radius: 50px;
    transition: all 0.2s;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mainRefresh:hover {
    cursor: pointer;
    background-color: #b1304a;
    transform: scale(1.1);
    color: white;
}

.mainRefresh:hover > .backImg {
    -webkit-filter: invert(1);
    filter: invert(1);
}



.backImg {
    max-width: 30%;
    padding: 5px;
}

.backText {
    font-family: Fjalla;
    font-size: 1.5rem;
    text-align: center;
    width: 50%;
}