

.loginBox {
  width: 50%;
  border-radius: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* margin-left: 25%; */
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
}

.loginB {
  width: 50%;
  margin-top: 30px;
  margin-left: 25%;
  margin-bottom: 20px;
}

.loginLogo {
  display: flex;
  justify-content: center;margin-top: 30px;
}

.loginText {
  font-family: Fjalla;
  font-size: 30px;
  color: black;
  text-align: center;
}

.belowInfo {
  font-family: Inter;
  font-size: 13px;
  color: #444444;
}

.txtflds {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 20px;
  margin-left: 25%;
  margin-bottom: 30px;
}

.loginBtns {
  white-space: nowrap;
  background-color: #B33951;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: Poppins;
  cursor: pointer;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 15px;
  border-radius: 5px;
  font-weight: 600;
  z-index: 0;
}

.under-prompt{
  width: 100%;
  margin-top: 5px;
  font-size: 15px;
  font-weight: bold;
  font-family: Inter;
  word-wrap: break-word;
  color: #B33951;
  cursor: pointer;
  text-align: center;
}

.under-prompt:hover {
  text-decoration: underline;
}

.loginBtns:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.backEndFail {
  color: red;
  text-align: left;
  font-size: 12px;
  font-family: Inter;
}

@media screen and (max-width: 1285px) {
  
}

@media screen and (max-width: 960px) {

}