

.main404Box {
    width: 50%;
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* margin-left: 25%; */
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  }
  
  .sub404Box {
    width: 70%;
    margin: 10% 15%;
  }
  
  .text404 {
    text-align: center;
    font-family: Fjalla;
    font-size: 40px;
    color: black;
  }
  
  .belowInfo {
    font-family: Inter;
    font-size: 13px;
    color: #444444;
    text-align: center; 
    margin-top: 10px;
  }
  