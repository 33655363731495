.mainLoader {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.195);
    z-index: 20;
    overflow-y: hidden;
    pointer-events: none;
    user-select: none;
}

  .loaderImg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    z-index: 20;
  }
