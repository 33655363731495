.mainDashboard {
    z-index: 1;
    position: relative;
}

.mainBoxes {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}