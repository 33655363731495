.mainAdminFunctions {
    position: relative;
}

.mainAdd {
    width: 160px;
    z-index: 1;
    border: 2px solid #B33951 ;
    border-radius: 50px;
    transition: all 0.2s;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}

.actionBtn {
    align-items: center;
    padding: 5px 10px;
    z-index: 1;
    border: 2px solid #B33951 ;
    border-radius: 50px;
    transition: all 0.2s;
    background-color: #FFFFFF;
    font-family: Poppins;
    font-size: 1rem;
    text-align: center;
}

.actionBtn:hover {
    cursor: pointer;
    background-color: #b1304a;
    color: white;
}

.topBar {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.mainAdd:hover {
    cursor: pointer;
    background-color: #b1304a;
    transform: scale(1.05);
    color: white;
}

.mainAdd:hover > .addImg {
    -webkit-filter: invert(1);
    filter: invert(1);
}

.addImg {
    max-width: 30%;
}

.addText {
    font-family: Fjalla;
    font-size: 1.5rem;
    width: 100%;
}

.belowInfo {
    font-family: Inter;
    font-size: 13px;
    color: #444444;
    text-align: center;
}

.butts {
    box-sizing: border-box;
    white-space: nowrap;
    background-color: #B33951;
    border: none;
    color: white;
    padding: 15px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-family: Poppins;
    cursor: pointer;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    width: 60%;
    margin-top: 20px;
    margin-bottom: 15px;
    margin-left: 20%;
    border-radius: 5px;
    font-weight: 600;
    z-index: 0;
  }

  .butts:hover {
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
      0 17px 50px 0 rgba(0, 0, 0, 0.19);
  }