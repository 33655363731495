.allCards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: "column",
}

.topHead {
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    margin: 2%;
    font-family: Fjalla;
    margin-bottom: 0px;
    letter-spacing: 3px;
}

.belowSlider {
  text-align: center;
  margin-top: 50px;
  font-family: Poppins;
  font-weight: bold;
  color: black;
  margin-bottom: 0px;
  font-size: 20px;
  color: #B33951;
}

.belowSliderBelow {
  text-align: center;
  margin: 20px 10%;
  margin-top: 0px;
  font-family: Poppins;
  font-weight: bold;
  color: black;
}

.mainImgContainer {
    display: flex;
    justify-content: center;
    width: 70%;
    margin: 20px;
}

.mainImg {
    max-width: 100%;
    object-fit: contain;
}

@media screen and (max-width: 640px) {
    .topHead {
        font-size: 35px;
        margin: 40px;
    }
}