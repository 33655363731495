.projectSprite {
    display: block;
    width: 300px;
    height: 195px;
    background-image: url('../phaser/assets/Projects2.png');
    background-size: 1800px 1560px;
    margin-top: 10px;
}

.opportunitySprite {
    width: 300px;
    height: 195px;
    background-image: url('../phaser/assets/Opportunities.png');
    background-size: 1800px 975px;
    margin-top: 20px;
}

.projectDeets {
    padding: 30px;
}

.projectDeetsSub {
    font-size: 50px;
    font-family: Fjalla;
    text-align: center;
    color: white;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    margin-top: 10px;
}


.hurdleSprite {
    width: 300px;
    height: 195px;
    background-image: url('../phaser/assets/Hurdles.png');
    background-size: 1800px 975px;
    margin-top: 20px;
}


.mainBox {
    margin: 5%;
}

.mainName {
    font-size: 64px;
    font-family: Fjalla;
    
}

.evs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.mainTitle {
    color: #B33951;
    font-size: 40px;
    font-family: Fjalla;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
    text-decoration: underline;
}

.secondaryTitle {
    color: #B33951;
    font-size: 30px;
    font-family: Fjalla;
    margin-top: 30px;
    text-align: center;
    text-decoration: underline;
}

.cardDivider {
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.cardDividerSecondary {
    width: 50%;
}

.creatorText {
    margin-left: 15%;
    margin-bottom: -40px;
    font-family: Fjalla;
    font-size: 25px;
    color: black;
  }

  .gameHeading {
    text-align: center;
    font-family: Night;
    letter-spacing: 2px;
    font-weight: bold;
    text-decoration: underline;
    font-size: 40px;
    color: black;
    margin-bottom: 40px;
    margin-top: 40px;
  }

.attris {
    font-family: Poppins;
    font-weight: bold;
    font-size: 32px;
    color: rgb(179, 57, 81);
    margin-bottom: 20px;
    word-wrap: break-word;
}

.attrisMail {
    color: black;
    margin-left: 20px;
}

.mainPfp {
    display: inline-block;
    border-radius: 100%;
    width: 76px;
    height: 76px;
    color: white;
    background-color: #B33951;
    margin-right: 20px;
    font-size: 50px;
    text-align: center;
    padding-top: 10px;
}

.mainAdminFunctions {
    position: relative;
}

.mainAdd {
    width: 160px;
    z-index: 1;
    border: 2px solid #B33951 ;
    border-radius: 50px;
    transition: all 0.2s;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}

.actionBtn {
    align-items: center;
    padding: 5px 10px;
    z-index: 1;
    border: 2px solid #B33951 ;
    border-radius: 50px;
    transition: all 0.2s;
    background-color: #FFFFFF;
    font-family: Poppins;
    font-size: 1rem;
    text-align: center;
}

.actionBtn:hover {
    cursor: pointer;
    background-color: #b1304a;
    color: white;
}

.topBar {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.mainAdd:hover {
    cursor: pointer;
    background-color: #b1304a;
    transform: scale(1.05);
    color: white;
}

.mainAdd:hover > .addImg {
    -webkit-filter: invert(1);
    filter: invert(1);
}

.addImg {
    max-width: 30%;
}

.addText {
    font-family: Fjalla;
    font-size: 1.5rem;
    width: 100%;
}

.belowInfo {
    font-family: Poppins;
    font-size: 13px;
    color: #444444;
    text-align: center;
}

.butts {
    box-sizing: border-box;
    white-space: nowrap;
    background-color: #B33951;
    border: none;
    color: white;
    padding: 15px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-family: Poppins;
    cursor: pointer;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    width: 60%;
    margin-top: 20px;
    margin-bottom: 15px;
    margin-left: 20%;
    border-radius: 5px;
    font-weight: 600;
    z-index: 0;
  }

  .butts:hover {
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
      0 17px 50px 0 rgba(0, 0, 0, 0.19);
  }

  .cashWrapper {
    width: 100%;
    color: white;
    font-family: Night;
    font-weight: bold;
    font-size: 9rem;
    text-shadow: 4px 0 #000000, -2px 0 #000000, 0 2px #000000, 0 -2px #000000,1px 1px #000000, -1px -1px #000000, 1px -1px #000000;
    text-align: center;
    white-space: nowrap;
}

  .moneyStackWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
    margin-left: 16rem;
  }

  .moneyStack {
    margin-left: -16rem;
  }

  .money {
    height: 7rem;
    object-fit: contain;
    vertical-align: middle;
  }

  .tokenWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    color: white;
    justify-content: space-evenly;
    text-shadow: 4px 0 #000000, -2px 0 #000000, 0 2px #000000, 0 -2px #000000,1px 1px #000000, -1px -1px #000000, 1px -1px #000000;
  }

  .tokenBox {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    font-family: Night;
    font-size: 6rem;
    font-weight: bold;
    object-fit: contain;
  }

  .token {
    width: 6rem;
    object-fit: contain;
    vertical-align: middle;
    margin-left: 30px;
  }