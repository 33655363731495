.mainCreateRoom {
    position: relative;
}

.gameRules {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: top;
    width: 100%;
    margin-top: 10px;
}

.ourEvents {
    font-family: Fjalla;
    font-size: 40px;
    color: black;
}

.ourEvents2 {
    font-family: Fjalla;
    font-size: 30px;
    color: black;
    margin-top: 60px;
}

.belowInfo {
    font-family: Inter;
    font-size: 13px;
    color: #444444;
    text-align: center;
}

.txtflds {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin-top: 10px;
    margin-left: 20%;
  }

.workshops {
    font-size: 15px;
    font-family: Inter;
    font-weight: bold;
    margin: 20px;
}

.topThings {
    display: flex;
    justify-content: space-between;
}

.createEventWrapper img {
    padding: 12px;
    border-radius: 10px;
    transition: all 0.2s;
}

.midPart {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.calendarSection {
    width: 45%;
    margin-top: 40px;
    cursor: pointer;
}

.listSection {
    width: 45%;
}

.createEventWrapper img:hover {
    transform: scale(1.03);
    background-color: #B33951;
    color: white;
    cursor: pointer;
}

.butts {
    box-sizing: border-box;
    white-space: nowrap;
    background-color: #B33951;
    border: none;
    color: white;
    padding: 15px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-family: Poppins;
    cursor: pointer;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    width: 60%;
    margin-top: 20px;
    margin-bottom: 15px;
    margin-left: 20%;
    border-radius: 5px;
    font-weight: 600;
    z-index: 0;
  }

  .creatorText {
    margin-left: 15%;
    margin-bottom: -40px;
    font-family: Fjalla;
    font-size: 25px;
    color: black;
  }

  .chipstags2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    height: 150px;
    overflow-y: scroll;
    border-radius: 10px;
    margin-top: 10px;
  }

  .butts:hover {
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
      0 17px 50px 0 rgba(0, 0, 0, 0.19);
  }