.mainOngoing {
    position: relative;
}

.belowInfo {
    font-family: Inter;
    font-size: 13px;
    color: #444444;
    text-align: center;
    margin-bottom: 30px;
}