.mainImg {
    width: 50%;
}

.mainContainer {
    width: 100%;
}

.imgContainer {
    display: flex;
    justify-content: center;
}

.btnContainer {
    display: flex;
    justify-content: center;
    margin: 30px;
}

.menuBtn {
  position: relative;
  border: none;
  background: transparent;
  padding: 0px;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-family: Night;
  font-weight: bold;
  width: 300px;
}

.menuBtnShadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(0deg 0% 0% / 0.25);
  will-change: transform;
  transform: translateY(2px);
  transition:
    transform
    600ms
    cubic-bezier(.3, .7, .4, 1);
}

.menuBtnEdge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(
    to left,
    hsl(340deg 100% 16%) 0%,
    hsl(340deg 100% 32%) 8%,
    hsl(340deg 100% 32%) 92%,
    hsl(340deg 100% 16%) 100%
  );
}

.menuBtnFront {
  display: block;
  padding: 20px;
  position: relative;
  border-radius: 12px;
  color: white;
  background: hsl(345deg 100% 47%);
  will-change: transform;
  transform: translateY(-4px);
  transition:
    transform
    600ms
    cubic-bezier(.3, .7, .4, 1);
    font-size: 2rem;
    padding: 12px 42px;
}

.menuBtn:hover {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
}

.menuBtn:hover .menuBtnFront {
  transform: translateY(-6px);
  transition:
    transform
    250ms
    cubic-bezier(.3, .7, .4, 1.5);
}

.menuBtn:active .menuBtnFront {
  transform: translateY(-2px);
  transition: transform 34ms;
}

.menuBtn:hover .menuBtnShadow {
  transform: translateY(4px);
  transition:
    transform
    250ms
    cubic-bezier(.3, .7, .4, 1.5);
}

.menuBtn:active .menuBtnShadow {
  transform: translateY(1px);
  transition: transform 34ms;
}

.menuBtn:focus:not(:focus-visible) {
  outline: none;
}