.outerLive {
    display: flex;
    justify-content: center;
}

.timesFlex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.liveContainer  {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
  }

  .liveCircle {
    border-radius: 50%;
    background-color: red;
    width: 20px;
    height: 20px;
    position: absolute;
    opacity: 0;
    animation: scaleIn 4s infinite cubic-bezier(.36, .11, .89, .32);
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
    to {
      transform: scale(2, 2);
      opacity: 0;
    }
  }