body,html {
  margin: 0px;
  padding: 0px;
 
}

html {
  overflow-y: scroll;
}

body {
  background-image: url("../src/assets/bg.png");

}

@font-face {
  font-family: Fjalla;
  src: url("./fonts/FjallaOne-Regular.woff2");
}

@font-face {
  font-family: Poppins;
  src: url("./fonts/Poppins-Regular.woff2");
}


@font-face {
  font-family: Inter;
  src: url("./fonts/Inter-Regular.woff2");
}

@font-face {
  font-family: K2D;
  src: url("./fonts/K2D-Regular.woff2");
}
