.mainBox {
    border-radius: 20px;
    width: 170px;
    aspect-ratio: 1;
    z-index: 0;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    position: relative;
    transition: all 0.2s;
    margin: 5%;
}

.mainBox:hover {
    background-color: #c63754;
    color: #FFFFFF;
    cursor: pointer;
}
.mainBox:hover > .imgBox {
    -webkit-filter: invert(1);
    filter: invert(1);
}



.imgBox {
    max-width: 50%;
    margin-left: 25%;
    margin-top: 10%;
}

.textBox {
    font-family: Fjalla;
    font-size: 1.3rem;
    text-align: center;
    word-wrap: break-word;
    margin-top: 10px;
}